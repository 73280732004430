import koko from './koko-framework/koko'
import { Howler } from 'howler'

// basic preloader - comment out for Penna example
import { PreloaderView } from './view/preloader'

import { v, a, au } from './koko-framework/shorthand'

// basic demo - comment out these 2 lines for Penna example
import app from './koko-framework/app'
import { SnowEffect } from './view/snowEffect'
import { GameView } from './randomised-runner/view/randomised-runner-game-view'
import { getAssetsListForPreloading } from './assets/assets'
import * as TWConnect from './twitch-connector/tw-connect.js';
import { MenuPixiView } from './view/MainMenuPixiView.js'
import { ConfettiView } from './randomised-runner/view/confetti-view.js'
import {isMobile} from 'react-device-detect';
import { LANGUAGE_SETTINGS } from './model/config.js'
import { GAME_MODE } from './contexts/TwitchContext.js'
import { SHOW_GAME_WITH_NO_LOGIN, SWITCH_OFF_TIME } from '../config/switch-off-config.js'
// Penna example setup stuff - un-comment for Penna example
// import { setupPennaViews } from './koko-framework/click-engine/orth/penna/main-setup'
// import { setupPennaAudio } from './koko-framework/click-engine/orth/penna/main-setup'

const main = {
    soundsToLoad: 0,

    reactAssetsToLoad: 0,
    reactAssetsLoadedCount: 0,
    reactAssetLoadProgressHandler: null,

    switchedOff: SHOW_GAME_WITH_NO_LOGIN ? false : (Date.now() / 1000 > SWITCH_OFF_TIME),

    init: function () {
        console.log('INIT GAME')
        this.setupViews();
        this.setupComplete();
        if(GAME_MODE.isStreamer) TWConnect.InitTwitchConnect();
      //  if(localStorage.getItem('isStreamer') == 'true' ) 
    },

    setupViews: function () {
        // basic demo - comment out these 2 lines for Penna example
        koko.views.add('preloader', new PreloaderView(), app.stage);
        // koko.views.add('demoview', new SnowEffect(), app.stage);
        koko.views.add('menuBgPixi', new MenuPixiView(), app.stage);
        koko.views.add('gameView', new GameView(), app.stage);
        koko.views.add('confettiView', new ConfettiView(), app.stage);

        // Penna example - un-comment to add in Penna views...
        // setupPennaViews();
    },

    setupAudio: function () {
        koko.audio.dir = 'https://d17sm93bt61fgk.cloudfront.net/audio/';

        koko.audio.init();
        koko.audio.addSoundGroup('music', 0.5);
        koko.audio.addSoundGroup('sfx', 0.5);

        this.addSound('button', 'button', 0.2, 'sfx', 1);

        if (!main.switchedOff) {
            this.addSound('collect', 'Collect', 1, 'sfx', 1);
            this.addSound('collect_main', 'collectGOAT', 1, 'sfx', 1);
            this.addSound('collect_special', 'collectSPECIAL', 1, 'sfx', 1);
            this.addSound('jump', 'jump', 1, 'sfx', 1);

            this.addSound('jump1', 'ArcadeJump_1', 1, 'sfx', 1);
            this.addSound('jump2', 'ArcadeJump_2', 1, 'sfx', 1);
            this.addSound('jump3', 'ArcadeJump_3', 1, 'sfx', 1);
            this.addSound('jump4', 'ArcadeJump_4', 1, 'sfx', 1);
            this.addSound('jump5', 'ArcadeJump_5', 1, 'sfx', 1);
            this.addSound('jump6', 'ArcadeJump_6', 1, 'sfx', 1);
            this.addSound('jump7', 'ArcadeJump_7', 1, 'sfx', 1);

            this.addSound('death', 'LifeLost', 1, 'sfx', 1);

            this.addSound('bonus_goat', 'bonus-GOAT', 1, 'sfx', 1);
            this.addSound('collide', 'dino-hit', 1, 'sfx', 1);

            this.addSound('hitFireBasket', 'fire-basket', 1, 'sfx', 1);
            this.addSound('hitSpike', 'spike-ball', 1, 'sfx', 1);
            this.addSound('hitTnt', 'tnt', 1, 'sfx', 1);

            this.addSound('bounce', 'bounce', 1, 'sfx', 1);

            this.addSound('menu_music', 'menuMusic', 1, 'music', 1);

            // Penna example - un-comment to add in Penna audio...
            // setupPennaAudio(this);

            if (isMobile) {
                this.loadLocalisedVidAudio(LANGUAGE_SETTINGS.lang);
            }
        }

        Howler.volume(1);
    },

    loadLocalisedVidAudio: function (langCode = 'en') {
       /* if (isMobile) {
            if (typeof au.sounds['vid_' + langCode] !== 'undefined') return;
            this.addSound('vid_' + langCode, 'vids/game-video-' + langCode, 1, 'sfx', 1);
        }*/
    },

    // Use this addSound function if you want the preloader to take in to account audio loading
    addSound: function (name, src, volume, group, size) {
        this.soundsToLoad++;
        koko.audio.add(name, src, volume, group, size);
    },

    preloadReactAssets: function(assetsArray) {
        if (window.preloadedImages === undefined) {
            window.preloadedImages = [];
            this.reactAssetsToLoad = 0;
        }
        if (Array.isArray(assetsArray)) {
            assetsArray.forEach((image) => {
                const newImage = new Image();
                newImage.onload = this.imageLoaded;
                newImage.src = image;
                window.preloadedImages.push(newImage);
                this.reactAssetsToLoad++;
            });
        } else {
            for (let p in assetsArray) {
                const newImage = new Image();
                newImage.onload = this.imageLoaded;
                newImage.src = assetsArray[p];
                window.preloadedImages.push(newImage);
                this.reactAssetsToLoad++;
            }
        }
        // console.log('Preload react assets: ', assetsArray, window.preloadedImages, this.reactAssetsToLoad, this.reactAssetsLoadedCount);
    },

    imageLoaded: function(e) {
        // console.log('REACT ASSET LOADED: ', e, main.reactAssetLoadProgressHandler);
        main.reactAssetsLoadedCount++;
        if (main.reactAssetLoadProgressHandler) {
            // console.log('Reporting react assets loaded...');
            main.reactAssetLoadProgressHandler();
        }
    },

    setupComplete: function () {
        // check if mobile, if so add style to mobile-push-header
        if (koko.client.system.isMobile) {
            // not needed on android, maybe ios?
            // document.getElementById('mobile-push-header').style.height = '10px';
        }

        // Do we need to try loading user? Load from cookie?
        // debug.log('load user');
        // mainUser.load()
        // v.get()

        // load the preloader graphics, before showing anything
        v.each(['preloader'], 'queueAssets');
        //load manifest
        a.loadManifest(function (p) {
            //LOADING PROGRESS EVENT
        }, function () {

            main.preloadReactAssets(getAssetsListForPreloading());
            //queue assets from all other menus
            if (!main.switchedOff) {
                v.allBut(['preloader'], 'queueAssets');
            }

            //show the preloader
            v.each(['preloader'], 'transitionIn');
        });
        // preloader triggers loading
    },

    //if extra load called, use handler like this
    // userLoaded: function(){
    // 	//load the preloader graphics, before showing anything
    // 	v.each(['preloader'], 'loadAssets');
    // 	v.each(['preloader'], 'queueAssets');
    // 	//load manifest
    // 	a.loadManifest(function(p){
    // 		//LOADING PROGRESS EVENT
    // 	}, function() {
    //
    // 		//queue assets from all other menus
    // 		v.allBut(['preloader'], 'queueAssets');
    //
    // 		//show the preloader
    // 		v.each(['preloader'], 'transitionIn');
    //
    // 		//load them sounds yo
    // 		//au.loadSounds();
    // 	});
    // }
}

export { main as default }

