export const SHOW_GAME_WITH_NO_LOGIN = true; // Show game without needing to log in (skip log in screen), overrides SWITCH_OFF_TIME
export const SWITCH_OFF_TIME = 1702670400;  // UNIX Timestamp in seconds (NOT millis) 15/12/2023 20:00 gmt
                                            // Use https://www.epochconverter.com/

export const FINAL_SCORES = [
    {
        "name": "jColfej",
        "score": 2183,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/jcolfej-profile_image-da459039e2b2e912-150x150.jpeg",
        "timeSubmitted": 1702403285628
    },
    {
        "name": "CallMeInfinity",
        "score": 2176,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/2515e314-0f4d-49b8-97da-971cbf76bd7b-profile_image-150x150.png",
        "timeSubmitted": 1702127685184
    },
    {
        "name": "francoolmedocarc",
        "score": 2125,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/b73a2087-3835-490c-b81c-2a41d1814cb5-profile_image-150x150.png",
        "timeSubmitted": 1702140860690
    },
    {
        "name": "ilondaBob",
        "score": 2069,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/dff2a98d-ad36-477b-8d99-971091dc6762-profile_image-150x150.png",
        "timeSubmitted": 1702122706342
    },
    {
        "name": "Triple6__",
        "score": 2024,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/88024792-42f9-4397-91ec-1fb4d3060a64-profile_image-150x150.png",
        "timeSubmitted": 1702109710953
    },
    {
        "name": "elfidelfie",
        "score": 2023,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/adc91a02-03dc-4a6c-a69c-05c41d23a9c6-profile_image-150x150.png",
        "timeSubmitted": 1702120905129
    },
    {
        "name": "PewDiePie",
        "score": 2017,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/e875d378-aee5-4ae3-a421-922db91d691f-profile_image-150x150.png",
        "timeSubmitted": 1702076731239
    },
    {
        "name": "kahzukie",
        "score": 2000,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/148a7183-9970-4a5d-a5a8-af36a5cee8c0-profile_image-150x150.png",
        "timeSubmitted": 1702085664907
    },
    {
        "name": "NLINEEEE",
        "score": 1966,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/e87630e7-7bc9-41fb-bf31-91006793d5e8-profile_image-150x150.jpeg",
        "timeSubmitted": 1702241603369
    },
    {
        "name": "alkinjel",
        "score": 1897,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/alkinjel-profile_image-fc4ef4c765bb44a5-150x150.jpeg",
        "timeSubmitted": 1702439841206
    },
    {
        "name": "Decicus",
        "score": 1882,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/bc32f15a-2922-43fd-a3b3-899ff7d58a45-profile_image-150x150.png",
        "timeSubmitted": 1702303753290
    },
    {
        "name": "Franzi__199",
        "score": 1878,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/25f22aa4-7d2d-493f-bf2d-9326e285b961-profile_image-150x150.jpeg",
        "timeSubmitted": 1702246590932
    },
    {
        "name": "MashaeI",
        "score": 1874,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/8ed5976e-4ded-43b7-b013-a97eec69414b-profile_image-150x150.png",
        "timeSubmitted": 1702339870134
    },
    {
        "name": "yarraklofven",
        "score": 1850,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/9e932d44-4e31-4450-a631-158fc1cc0854-profile_image-150x150.png",
        "timeSubmitted": 1702299035160
    },
    {
        "name": "scumpwagen",
        "score": 1823,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/b5c8364f-048c-4331-8238-90c07935884d-profile_image-150x150.png",
        "timeSubmitted": 1702283815547
    },
    {
        "name": "MyatnyjChaj3",
        "score": 1803,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/a6a8e2e2-31db-4de3-a3e2-05fe66869b9c-profile_image-150x150.jpeg",
        "timeSubmitted": 1702331496938
    },
    {
        "name": "SERVPHIM",
        "score": 1802,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/8aaec09e-d1ff-432f-86e8-f7838ff0d9d8-profile_image-150x150.png",
        "timeSubmitted": 1702252333817
    },
    {
        "name": "여우는르나르",
        "score": 1801,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/3f1f5179-fda5-44bb-9371-a246e70b69c1-profile_image-150x150.png",
        "timeSubmitted": 1702560163903
    },
    {
        "name": "joope24",
        "score": 1796,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/deafbbec-73f7-49c5-8760-9861cf019da0-profile_image-150x150.png",
        "timeSubmitted": 1702138828169
    },
    {
        "name": "KuchikukanHibiki",
        "score": 1795,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/c6b00bc4f61cd933-profile_image-150x150.png",
        "timeSubmitted": 1702597933556
    }/*,
    {
        "name": "naufaltresna",
        "score": 1789,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/738abad8-5d63-413d-8d1e-0cbe7171a8b0-profile_image-150x150.jpeg",
        "timeSubmitted": 1702230085989
    },
    {
        "name": "とまとまと__",
        "score": 1786,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/10e6c4e6-c8b2-4e0e-9c24-515405ee3922-profile_image-150x150.png",
        "timeSubmitted": 1702555529390
    },
    {
        "name": "LevDST",
        "score": 1780,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/fb788440-3159-41d9-b8fb-6ced0cb799ea-profile_image-150x150.png",
        "timeSubmitted": 1702435579626
    },
    {
        "name": "Maco_CH",
        "score": 1777,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/182943ca-e93e-41a7-a6df-923643819cb9-profile_image-150x150.jpeg",
        "timeSubmitted": 1702587596344
    },
    {
        "name": "boundless_beta",
        "score": 1773,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/3f93041d-0832-44bf-af5a-43126be94f90-profile_image-150x150.png",
        "timeSubmitted": 1702561939617
    },
    {
        "name": "Bighoodoo68",
        "score": 1771,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/0da72b08-a644-4e45-a6f6-4281f00d6bd6-profile_image-150x150.png",
        "timeSubmitted": 1702453770421
    },
    {
        "name": "itshuescaa",
        "score": 1770,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/a369ee8a-e484-49e7-ac75-66b49d05e388-profile_image-150x150.png",
        "timeSubmitted": 1702601146801
    },
    {
        "name": "xxavyy",
        "score": 1767,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/61255095-0858-4a1c-b26d-e3b84c347360-profile_image-150x150.png",
        "timeSubmitted": 1702305705652
    },
    {
        "name": "mdn5",
        "score": 1765,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/ac608403-1f83-4435-99d5-af5e2fb9c5f8-profile_image-150x150.png",
        "timeSubmitted": 1702485193344
    },
    {
        "name": "trekkie20",
        "score": 1760,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/038b2a6d-4e7f-4d5d-a049-1cf7022463ce-profile_image-150x150.jpeg",
        "timeSubmitted": 1702601155538
    },
    {
        "name": "푸림이",
        "score": 1758,
        "profilePic": "https://static-cdn.jtvnw.net/user-default-pictures-uv/dbdc9198-def8-11e9-8681-784f43822e80-profile_image-150x150.png",
        "timeSubmitted": 1702121742009
    },
    {
        "name": "yathrall",
        "score": 1758,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/7a9511e6-50c4-47fa-81e1-ab3088650b8a-profile_image-150x150.png",
        "timeSubmitted": 1702082066879
    },
    {
        "name": "EsperDG",
        "score": 1757,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/0f70dbea-ec65-421b-99cf-83950e10b235-profile_image-150x150.png",
        "timeSubmitted": 1702258970035
    },
    {
        "name": "Brybry_",
        "score": 1755,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/d7188985-55a5-4097-8a55-9273addf9063-profile_image-150x150.png",
        "timeSubmitted": 1702252642118
    },
    {
        "name": "fabs_arakaki",
        "score": 1752,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/49a087d8-866e-4849-8a82-d7b3284af8cb-profile_image-150x150.png",
        "timeSubmitted": 1702384620377
    },
    {
        "name": "josh0troy",
        "score": 1750,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/fbe3c83f-9d0b-4c4d-a64b-f730e12d3e27-profile_image-150x150.png",
        "timeSubmitted": 1702261319418
    },
    {
        "name": "NotCluster_",
        "score": 1743,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/7b5a482a-961d-41d2-affb-3b9c601a337d-profile_image-150x150.png",
        "timeSubmitted": 1702159612683
    },
    {
        "name": "Pinkuisa",
        "score": 1737,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/f68757e3-c6f8-4554-9e1c-a3531c2e8af5-profile_image-150x150.png",
        "timeSubmitted": 1702212882967
    },
    {
        "name": "HOmega_fr",
        "score": 1736,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/94195e4a-ad75-4941-9d9e-cb305bf5a61c-profile_image-150x150.png",
        "timeSubmitted": 1702322065952
    },
    {
        "name": "Lacrlmoza",
        "score": 1731,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/e1c4af0d-7e1d-42cd-946f-e43e3cced88a-profile_image-150x150.png",
        "timeSubmitted": 1702275831484
    },
    {
        "name": "rompebolas24",
        "score": 1730,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/96ef5716-6fe5-4a6d-9523-4f3bdc19e91c-profile_image-150x150.png",
        "timeSubmitted": 1702434804662
    },
    {
        "name": "ChocoboGuy",
        "score": 1730,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/f3bb875f-0db7-4f3c-bbf4-fe95f55b7632-profile_image-150x150.png",
        "timeSubmitted": 1702526206675
    },
    {
        "name": "SaggyLOL",
        "score": 1729,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/3bbfc1e2-4d61-4a6f-b982-36ee7c6f07f3-profile_image-150x150.png",
        "timeSubmitted": 1702529924114
    },
    {
        "name": "ソロタリー",
        "score": 1727,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/937a52f9-a3b0-4af7-bb38-cc0a628109dc-profile_image-150x150.png",
        "timeSubmitted": 1702310165561
    },
    {
        "name": "anntbro",
        "score": 1723,
        "profilePic": "https://static-cdn.jtvnw.net/user-default-pictures-uv/41780b5a-def8-11e9-94d9-784f43822e80-profile_image-150x150.png",
        "timeSubmitted": 1702107741364
    },
    {
        "name": "aquila_q",
        "score": 1721,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/681da41e-9765-4cfa-aa4f-14a56192b59c-profile_image-150x150.png",
        "timeSubmitted": 1702289300636
    },
    {
        "name": "reydelautismo",
        "score": 1719,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/00b4f340-74fa-42ba-90f1-26facfaeff74-profile_image-150x150.png",
        "timeSubmitted": 1702313531640
    },
    {
        "name": "Baertorv",
        "score": 1715,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/d30dc636-8276-4114-b596-595448db1f8a-profile_image-150x150.png",
        "timeSubmitted": 1702340504792
    },
    {
        "name": "jjrs00",
        "score": 1713,
        "profilePic": "https://static-cdn.jtvnw.net/user-default-pictures-uv/998f01ae-def8-11e9-b95c-784f43822e80-profile_image-150x150.png",
        "timeSubmitted": 1702452335234
    },
    {
        "name": "TheSimplePotato",
        "score": 1711,
        "profilePic": "https://static-cdn.jtvnw.net/jtv_user_pictures/b50269746c634f55-profile_image-150x150.png",
        "timeSubmitted": 1702118267266
    }
    */
];
