import React from 'react'
import ReactFullScreenView from '../koko-framework/ui/react-views/ReactFullScreenView';
import "./MainMenu.scss";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
  import { faQuestion, faRankingStar, faPlay } from '@fortawesome/free-solid-svg-icons'
import textLocaliser from '../koko-framework/ui/TextLocaliser';
import koko from '../koko-framework/koko';
import { REACT_VIEW_CONTROLLER } from '../Bootstrap';
import { assets } from '../assets/assets';

import gsap from 'gsap';
import { LANGUAGE_CHANGED_EVENT } from '../model/config';
import { indexOf } from 'underscore';

const jumpObjs = [assets.SpikeBall, assets.TntBarrel, assets.FireBasket];
export default class MainMenuView extends ReactFullScreenView {
    state = {
      isGoatObj: true,
      jumpObj: Math.floor(Math.random() * 3),
      forceUpdate: false,
    }
    pumpTween = null;
    jumpObjTween = null;
    jumpObjTimeout = null;
    jumpTimeout = null;
    collectTimeout = null;
    collectTween = null;

    langChangeHandler = (e) => {
      this.setState({forceUpdate: !this.state.forceUpdate});
    }

    // make sure you call the superclass in didMount and willUnmount
    componentDidMount(props) {
        // I would recommend doing your stuff before calling super.componentDidMount,
        // as this may cause a callback...
        super.componentDidMount(props);
      console.log('SHOW MENU')
        koko.views.showViews(['menuStarsBgView', 'menuBgPixi', 'gameHUD']);
        REACT_VIEW_CONTROLLER.rootView.showMenuBg();

        console.log('Show main menu view');

        // this.jumpInterval = setInterval(this.dinoJump, 3000);
        this.jumpObjTimeout = setTimeout(this.addJumpObj, 600 /*+ Math.random() * 1000*/)
        this.pumpTwitch();
        
        if(!this.musicPlaying)
        {
          this.musicPlaying = true;
          koko.audio.play('menu_music', 1, true, 0, false);
        }
        document.removeEventListener(LANGUAGE_CHANGED_EVENT, this.langChangeHandler);
        document.addEventListener(LANGUAGE_CHANGED_EVENT, this.langChangeHandler);
    }
  transitionIn()
  {
    console.log('PLAY MUSIC')
   
    super.transitionIn();
  }
    componentWillUnmount() {
        // the super version of this removes a resize listener so, make sure you call this!
     

        // koko.views.hideViews(['menuStarsBgView']);
        if(this.jumpTimeout) clearTimeout(this.jumpTimeout);
        if (this.jumpTween) {
          this.jumpTween.kill();
        }
        if (this.jumpObjTween) {
          this.jumpObjTween.kill();
        }

        if(this.pumpTween) this.pumpTween.kill();

        if (this.collectTween) {
          this.collectTween.kill();
        }
       if(this.jumpObjTimeout) clearTimeout(this.jumpObjTimeout);
       if(this.collectTimeout) clearTimeout(this.collectTimeout);
    
        document.removeEventListener(LANGUAGE_CHANGED_EVENT, this.langChangeHandler);
        super.componentWillUnmount();
    }

    showHelp = (e) => {
      e.preventDefault();
        koko.views.showView('helpScreens');
        koko.views.hideViews(['mainMenu', 'menuStarsBgView', 'gameHUD'/*, 'menuBgPixi'*/]);
    }
    pumpTwitch = () => {
      const logo = document.getElementById('twitchLogo');
      this.pumpTween = gsap.to(logo, {scale: .9, duration: 0.23, repeat: -1, yoyo: true, ease: "power2.out"});
    }

    dinoJump = () => {
      if (!this.state.isGoatObj) {
        const dino = document.getElementById('menu_dino');
        this.jumpTween = gsap.to(dino, {marginBottom: 150, duration: 0.75, repeat: 1, yoyo: true, ease: "power2.out", onComplete: () => {dino.style.marginBottom = 0}});
      }
    }

    dinoCollect = () => {
      if (this.state.isGoatObj) {
        const jumpObj = document.getElementById('menu_jump_obj');
        this.jumpObjTween.kill();
        this.jumpObjTimeout = setTimeout(this.addJumpObj, 1200 /*+ Math.random() * 1000*/);
        this.collectTween = gsap.to(jumpObj, {marginBottom: 200, opacity: 0, duration: 1, ease: "power2.out"});
      }
    }

    addJumpObj = () => {
      if (this.collectTween) {
        this.collectTween.kill();
      }
      const isGoatObj = !this.state.isGoatObj;
      let newJumpObj = this.state.jumpObj;
      if (!isGoatObj) {
        let i = 0;
        while (newJumpObj === this.state.jumpObj && i < 10) {
          newJumpObj = Math.floor(Math.random() * 3);
          i++;
        }
      }
      this.setState({isGoatObj: isGoatObj, jumpObj: newJumpObj});
      const jumpEl = document.getElementById('menu_jump_obj');
      jumpEl.style.marginRight = 0;
      jumpEl.style.marginBottom = 0;
      jumpEl.style.opacity = 1;
      this.jumpObjTween = gsap.to(jumpEl, {marginRight: 2400, duration: 3, ease: "none", onComplete: () => {this.jumpObjTimeout = setTimeout(this.addJumpObj, 600 /*+ Math.random() * 1000*/)}});
      this.jumpTimeout = setTimeout(this.dinoJump, 800);
      if (isGoatObj) {
        this.collectTimeout = setTimeout(this.dinoCollect, 1300);
      }
    }

    showLeaderboard = (e) => {
      e.preventDefault();
      koko.views.showView(/*'gameoverView'*/ 'leaderboard' );
      koko.views.hideViews(['mainMenu', 'menuStarsBgView', 'gameHUD'/* , 'menuBgPixi' */]);
    }
    musicPlaying = false;
    startGame = (e) => {
      e.preventDefault();
      this.props.startGameFunc(e); 
      this.musicPlaying = false;
      // koko.audio.fadeSoundTo('menu_music', 0, 2000)
    }
    /* There a alot of spacer empty growing row divs here! */
    renderContent() {
      return (
        <>
          <div className='twitchLogo'>
            <img id="twitchLogo" src={assets.TwitchLogo} />
            <div className='playthroughTitle'><p>{textLocaliser.getText('logo_tag_line')}</p></div>
          </div>

          <div className='mm-holder'>
            <div className='grow'></div>
            <div className='mm-buttons'>
              <div className='grow'></div>
              <div className='buttonIcon mm-round-button' onClick={(e) => {this.showHelp(e)}}>
                <img className='icon' src={assets.TutorialIcon} alt='' />
              </div>
              <div className='buttonIcon mm-round-button' onClick={(e) => {this.showLeaderboard(e)}}>
                <FontAwesomeIcon className='icon' icon={faRankingStar} size="3x" />
              </div>
              <div onClick={(e) => { this.startGame(e) }} className='buttonIcon mm-play-button'>
                <FontAwesomeIcon className='icon' icon={faPlay} size="3x" />
              </div>
              <div className='grow'></div>
            </div>
          </div>

          <div className='footer'>
            <div className='view-row footer-links'>
              <div className='grow'></div>
              <div><a href="https://www.twitch.tv/p/legal/privacy-notice/" target='_blank'>{textLocaliser.getText('main_menu_footer_privacy')}</a></div>
              <div><a href="https://www.twitch.tv/p/legal/terms-of-service/" target='_blank'>{textLocaliser.getText('main_menu_footer_terms')}</a></div>
              <div><a href="https://www.twitch.tv/p/legal/accessibility/" target='_blank'>{textLocaliser.getText('main_menu_footer_accessibility')}</a></div>
              <div className='grow'></div>
            </div>
            <div className='view-row'>
              <div className='grow'></div>
              <div dangerouslySetInnerHTML={{ __html: textLocaliser.getText('main_menu_copyright') }}></div>
              <div className='grow'></div>
            </div>
          </div>

          <div className='menu-dino-holder'>
            <img id='menu_dino' className='menu-dino-run' src={assets.DinoRun} alt="" />
          </div>
          <img className='menu-jump-object' id="menu_jump_obj" src={this.state.isGoatObj ? assets.Collectable1 : jumpObjs[this.state.jumpObj]} style={{marginBottom: /*this.state.isGoatObj ? 50 :*/ 1}} alt="" />
        </>
      );
    }
}
