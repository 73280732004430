import React from 'react'
import PositionableReactView from '../koko-framework/ui/react-views/PositionableReactView';
import ReactFullScreenView from '../koko-framework/ui/react-views/ReactFullScreenView';
import "./GameHUD.scss";
import { TwitchContext } from '../contexts/TwitchContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestion, faRankingStar, faPlay } from '@fortawesome/free-solid-svg-icons'
import TwitchLoginButton from './components/TwitchLoginButton';
import { getPlayCountRoute } from '../../api/routes';
import axios from "../../api/axios";
import textLocaliser from '../koko-framework/ui/TextLocaliser';
import { assets } from '../assets/assets';
import DropDownList from '../../components/forms/DropDownList/DropDownList';
import { LANGS } from '../randomised-runner/model/randomised-runner-text';
import { LANGUAGE_CHANGED_EVENT, LANGUAGE_SETTINGS, verifyLang } from '../model/config';
import { au, v } from '../koko-framework/shorthand';
import app from '../koko-framework/app';
import main from '../main';


export default class GameHUDView extends PositionableReactView {

  state = {
      playCount: 0,
      forceUpdate:false,
      muted: au.mute, 
  }

  TIME_BETWEEN_FETCH = 10000

  static contextType = TwitchContext
    // make sure you call the superclass in didMount and willUnmount
    componentDidMount(props) {
        // I would recommend doing your stuff before calling super.componentDidMount,
        // as this may cause a callback...
        super.componentDidMount(props);
        this.updatePlayCount();
        this.setPosition(0, 0, false);

        this.setState({muted: au.mute});
    }
  
    componentWillUnmount() {
        // the super version of this removes a resize listener so, make sure you call this!
        super.componentWillUnmount();
    }

    toggleMute = () => {
      au.toggleMute();
      this.setState({muted: au.mute});
    }

    goFullScreen = () => {
      app.goFullScreen();
    }

    openMainMenu(e) {
      e.preventDefault();
      localStorage.setItem('isGOAT', false)
      v.showView('mainMenu');
      v.showView('gameHUD');
      v.hideViews(['gameover_leaderboard', 'gameover_gameHUD']);
    }

    updateTimeOut = null;
    isFetching = false;
    updatePlayCount() {
      if(!this.isFetching)
      {
        this.isFetching = true;
        clearTimeout(this.updateTimeOut)
        axios.get(getPlayCountRoute, {params:{lang:LANGUAGE_SETTINGS.lang}, withCredentials:true})
        .then((response) => {
            console.log('Updated Play Count:', response.data.settingsData.playCount )
          this.setState({
                playCount: response.data.settingsData.playCount
            });
            console.log('VIDEO ID =', LANGUAGE_SETTINGS.lang, response.data.settingsData.videoSrc)
            localStorage.setItem('videoID', response.data.settingsData.videoSrc)
            this.isFetching = false;
            this.updateTimeOut = setTimeout(() =>{this.updatePlayCount()},90000)
        })
        .catch(function (error) {
          console.log('ERROR')
          this.isFetching = false;
          this.updateTimeOut = setTimeout(() =>{this.updatePlayCount()}, 90000)
        });
      }
    }

    /* There a alot of spacer empty growing row divs here! */
    renderContent() {
        return (
          <>
          {/* this.context.state.isLoggedIn &&   <div className="centered"> 
          <TwitchLoginButton></TwitchLoginButton>
          <p> GAME HUD SHOWN</p>
                 <img src={this.context.state.profilePic} />
                 <p>{this.context.state.username}</p>
                 <p>Play count:- {this.state.playCount}</p>
              </div>
        */}

            <div className='menu-hud-top'>
              <div className='play-count-holder'>
                <div className='header-text'>
                  {textLocaliser.getText("playCountTitle")}
                </div>
                <div className='count-text'>
                  {this.state.playCount.toLocaleString()}
                </div>
                <img className='dino-icon' alt="" src={assets.MenuHud3DDino} />
              </div>
              <div className='grow'></div>
              
                {/* <TwitchLoginButton></TwitchLoginButton>
                <img className='profile-pic' src={this.context.state.profilePic} /> */}

                {this.props.gameoverStyle &&
                  <div className='hud-top-button btn'>
                    <img className='icon' alt="" src={assets.HomeIcon} onClick={this.openMainMenu} />
                  </div>
                }
                
                <div className='hud-top-button btn'>
                  <img className='icon' alt="" src={this.state.muted ? assets.SoundOff : assets.SoundOn} onClick={this.toggleMute} />
                </div>

                <div className='hud-top-button btn'>
                  <img className='icon' alt="" src={assets.FullScreen} onClick={this.goFullScreen} />
                </div>

                {/*<div className='hud-top-button'>
                  <img className='icon' alt="" src={assets.InfoIcon} />
              </div>*/}
              
                {/* <div className='hud-top-button'>
                  <img className='icon' alt="" src={assets.ShareIcon} />
                </div> */}

               

                <DropDownList items={LANGS} selectedValue={LANGUAGE_SETTINGS.lang} selectFunc={(e, id, item) =>{this.setState({forceUpdate:!this.state.forceUpdate}); verifyLang(item.id); main.loadLocalisedVidAudio(LANGUAGE_SETTINGS.lang); this.updatePlayCount(); document.dispatchEvent(new CustomEvent(LANGUAGE_CHANGED_EVENT))}}></DropDownList>
              
            </div>

          </> 
        );
    }
}